import { useParams } from "react-router";
import { Link } from "react-router-dom";
import "./LessonNavbarMobile.css";
import logo from "../../../images/logo_languo_biale.png";
import flag from "./dymek_angielski.png";
import Store from "../../../../Store";
import getLanguageFlag from "../../../common/functions/getLanguageFlag";

function LessonNavbarMobile(props) {
  const params = useParams();

  return (
    <div className="lessons-page__level-bar-wrapper-mobile">
      <div className="lessons-page__logo-wrapper-mobile">
        <Link to="/platform">
          <img src={logo} className="lessons-page__logo-mobile" />
        </Link>
      </div>
      <div className="lessons-page__levels-wrapper-mobile">
        <Link
          className={`lessons-page__level-bar-mobile lessons-page__level-bar-mobile--first ${
            params.level === "1" ? "lessons-page__level-bar-mobile--active" : ""
          }`}
          to={`/platform/lessons/${params.pairId}/1`}
        >
          <p className="lesssons-page__level-number-mobile">1</p>
        </Link>
        <Link
          className={`lessons-page__level-bar-mobile lessons-page__level-bar-mobile--second ${
            params.level === "2" ? "lessons-page__level-bar-mobile--active" : ""
          }`}
          to={`/platform/lessons/${params.pairId}/2`}
        >
          <p className="lesssons-page__level-number-mobile">2</p>
        </Link>
        <Link
          className={`lessons-page__level-bar-mobile lessons-page__level-bar-mobile--third ${
            params.level === "3" ? "lessons-page__level-bar-mobile--active" : ""
          }`}
          to={`/platform/lessons/${params.pairId}/3`}
        >
          <p className="lesssons-page__level-number-mobile">3</p>
        </Link>
      </div>
      <div className="lessons-page__grammar-buttons-wrapper-mobile">

        {props.langPair && ["cs-en", "sk-en", "sk-es", "sk-de"].indexOf(props.langPair.replace(/[0-9]/g, '')) !== -1 && <button
            className="lessons-page__grammar-button-mobile"
            onClick={() => props.setIsGrammarModalOpen(true)}
          >
            G
          </button>}
          {props.langPair && ["cs-en", "sk-en", "cs-ru", "sk-ru", "sk-es", "sk-de"].indexOf(props.langPair.replace(/[0-9]/g, '')) !== -1 && <button
            className="lessons-page__grammar-button-mobile"
            onClick={() => props.setIsAlphabetModalOpen(true)}
          >
            Aa
          </button>}
      </div>
    </div>
  );
}

export default LessonNavbarMobile;
