import React, { useContext, useEffect } from "react";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router";
import { LanguageContext } from "./common/contexts/LanguageContext";
import Store, { POSSIBLE_LANGS } from "./Store";

const ParamsChecker = () => {
  const { pathname } = useLocation();
  const {lang} = useParams();
  const navigate = useNavigate();
  const {setLang} = useContext(LanguageContext)

  useEffect(() => {
    console.log(lang)
     if (lang) {
      setLang(lang === 'cz' ? 'cs' : lang)
      Store.setUserLanguage(lang === 'cz' ? 'cs' : lang);
    }
    else{
      const pathParts = pathname.split("/");
      pathParts[1] = 'cs'
      navigate(pathParts.join('/'));
    }



  }, [pathname]);

  return <></>;
};

export default ParamsChecker;
