import { useEffect, useState } from 'react';
import api from '../../../platform/api/api';
import MainButton from '../../../platform/components/Buttons/MainButton/MainButton';
import Setting from './components/Setting/Setting';
import './MailSettings.css';

const MAIL_TYPE = {
    WELCOME: 'welcome',
    PASSWORD_RECOVERY: 'passwordRecovery',
};

function MailSettings() {
    const [currentSettingsWelcome, setCurrentSettingsWelcome] = useState([]);
    const [currentSettingsRecovery, setCurrentSettingsRecovery] = useState([]);

    const createCurrentSetting = (language, currentSetting) => ({
        languageName: language.language,
        languageId: language.id,
        active: false,
        ...currentSetting,
    });

    const computeCurrentSettings = (
        languages,
        settingsWelcome,
        settingsRecovery
    ) => {
        const computedSettingsWelcome = [];
        const computedSettingsRecovery = [];
        languages.forEach((language) => {
            computedSettingsWelcome.push(
                createCurrentSetting(
                    language,
                    settingsWelcome.find(
                        ({ languageId }) => languageId === language.id
                    )
                )
            );

            computedSettingsRecovery.push(
                createCurrentSetting(
                    language,
                    settingsRecovery.find(
                        ({ languageId }) => languageId === language.id
                    )
                )
            );
        });

        setCurrentSettingsWelcome(computedSettingsWelcome);
        setCurrentSettingsRecovery(computedSettingsRecovery);
    };

    const setCurrentSettingsWelcomeAt = (index, settings) => {
        setCurrentSettingsWelcome((prev) => {
            prev[index] = settings;
            return [...prev];
        });
    };

    const setCurrentSettingsRecoveryAt = (index, settings) => {
        setCurrentSettingsRecovery((prev) => {
            prev[index] = settings;
            return [...prev];
        });
    };

    const fetchData = () => {
        setCurrentSettingsWelcome([]);
        setCurrentSettingsRecovery([]);
        Promise.all([
            api.get(`/language-pair?version=1`),
            api.get(`/mail-settings/${MAIL_TYPE.WELCOME}`),
            api.get(`/mail-settings/${MAIL_TYPE.PASSWORD_RECOVERY}`),
        ]).then(
            ([
                lanuagePairV1,
                mailSettingsWelcome,
                mailSettingsRecovery,
            ]) => {
                const languagesFromUnique = lanuagePairV1.data
                    .map((pair) => pair.languageFrom)
                    .filter(
                        (value, index, self) =>
                            index === self.findIndex((t) => t.id === value.id)
                    );
                computeCurrentSettings(
                    languagesFromUnique,
                    mailSettingsWelcome.data,
                    mailSettingsRecovery.data
                );
            }
        );
    };

    useEffect(() => {
        fetchData();
    }, []);

    const saveSettings = (type, settings) => {
        const settingsToSave = settings.map(
            ({ fromEmail, fromName, subject, templateId, languageId, active }) => ({
                fromEmail, fromName, subject, templateId, languageId, active
            })
        ).filter(({ fromEmail, fromName, subject, templateId, languageId }) => Object.values({ fromEmail, fromName, subject, templateId, languageId }).every(Boolean));

        api.post(`/mail-settings`, { type, settings: settingsToSave }).then(fetchData)
    };

    return (
        <div className="mail-settings-page">
            <div className="mail-settings-page__type-wrapper">
                <h2>Maile powitalne</h2>
                <div className='mail-settings-page__settings-wrapper'>

                    {currentSettingsWelcome.map((setting, index) => (
                        <Setting
                            key={index}
                            setSetting={setCurrentSettingsWelcomeAt}
                            setting={setting}
                            index={index}
                        />
                    ))}
                </div>
                <MainButton
                    onClick={() =>
                        saveSettings(MAIL_TYPE.WELCOME, currentSettingsWelcome)
                    }
                >
                    Zapisz
                </MainButton>
            </div>
            <div className="mail-settings-page__type-wrapper">
                <h2>Maile reset hasła</h2>
                <div className='mail-settings-page__settings-wrapper'>
                    {currentSettingsRecovery.map((setting, index) => (
                        <Setting
                            key={index}
                            setSetting={setCurrentSettingsRecoveryAt}
                            setting={setting}
                            index={index}
                        />
                    ))}
                </div>
                <MainButton
                    onClick={() =>
                        saveSettings(MAIL_TYPE.PASSWORD_RECOVERY, currentSettingsRecovery)
                    }
                >
                    Zapisz
                </MainButton>
            </div>
        </div>
    );
}

export default MailSettings;
