import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Store from "../../../../Store";
import MainButton from "../../Buttons/MainButton/MainButton";
import SecondaryButton from "../../Buttons/SecondaryButton/SecondaryButton";
import congrats from "./congrats.png";
import "./LessonModal.css";

function LessonModal({ activityData, loadNextActivity, languagePair, ...props }) {
  const params = useParams();
  return (
    <div
      className={
        "lesson-modal " +
        (props.isModalOpen ? " lesson-modal--active " : " ") +
        props.className
      }
    >
      <div className={"lesson-modal-box " + props.className}>
      <button
          className="lesson-modal__closing-button"
          type="button"
          onClick={() => {
            props.setIsModalOpen(false);
            loadNextActivity();
          }}
        >
          <FontAwesomeIcon
            icon={faXmark}
            className="main-modal__closing-button-icon"
          />
        </button>
        <p className="lesson-modal-box__header">  {Store.getText("lessonModalHeader")}</p>
        <p className="lesson-modal-box__text">
          {Store.getText("lessonModalText")}{" "}
          <b>
            {Store.getLessonName(activityData.level, activityData.lessonNumber, languagePair.version, languagePair.languageTo.languageShort)}
          </b>
        </p>
        <img className="lesson-modal-box__img" src={congrats} />
        <div className="lesson-modal-box__button-wrapper">
        <Link
            to={`/platform/lessons/${params.pairId}/${params.level}`}
            className="lesson-modal-box__link"
          >
            {" "}
            <MainButton className="lesson-modal-box__button lesson-modal-box__button--outline">
              {Store.getText("lessonModalToAllLessonText")}
            </MainButton>
          </Link>
          <SecondaryButton
            className="lesson-modal-box__button "
            onClick={() => {
              props.setIsModalOpen(false);
              loadNextActivity();
            }}
          >
            {Store.getText("lessonModalNextLessonText")}
          </SecondaryButton>

         
        </div>
      </div>
    </div>
  );
}

export default LessonModal;
