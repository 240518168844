import "./LessonsPage.css";
import api from "../../api/api";
import useCollapse from "react-collapsed";
import ProgressBar from "@ramonak/react-progress-bar";
import { useParams } from "react-router";
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Store from "../../../Store";
import getLevelColor from "../../common/functions/getLevelColor";
import Sidebar from "../../components/Sidebar/Sidebar";
import GrammarModal from "../../components/Modals/GrammarModal/GrammarModal";
import AlphabetModal from "../../components/Modals/AlphabetModal/AlphabetModal";
import ReturnButton from "../../components/Buttons/ReturnButton/ReturnButton";
import LessonNavbarDesktop from "./LessonNavbarDesktop/LessonNavbarDesktop";
import LessonNavbarMobile from "./LessonNavbarMobile/LessonNavbarMobile";
import bubble from "./dymek-lekcje.png";
import getLessonBg from "../../common/functions/getLessonBg";
import MainButton from "../../components/Buttons/MainButton/MainButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton/SecondaryButton";
import StatsContext from "../../stats/context/statsContext";
import withParamColector from "../../stats/hoc/withParamCollector";
import useIsVisible from "../../common/hook/useIsVisible";

function ExpandableListElement({
  subLessons,
  subLessonsBefore,
  pair,
  level,
  setLessonsLoaded
}) {
  const scrollElement = useRef(null);
  const [isExpanded, setExpanded] = useState(false);
  const wasExpanded = useRef(false);
  const [progress, setProgress] = useState(0);
  const [currentProgress, setCurrentProgress] = useState(0);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  const isVisible = useIsVisible(scrollElement);

  const { setLessonId } = useContext(StatsContext);

  const handleExpand = (bool) => {
    if(bool) wasExpanded.current = bool;
    setExpanded(bool)
  }

  const findLessonBefore = (lesson) => {
    return lesson.subNumber === 1
      ? subLessonsBefore[subLessonsBefore.length - 1]
      : subLessons[subLessons.findIndex((less) => less.id === lesson.id) - 1];
  };

  const isLessonStarted = (lesson) => {
    return (
      lesson.progress > 0 ||
      (!subLessonsBefore && lesson.subNumber === 1) ||
      findLessonBefore(lesson).progress === 1
    );
  };

  const getSublessonsProgress = (lessons) => {
    let progressSum = 0;
    lessons.forEach((lesson) => (progressSum += lesson.progress));
    setProgress(progressSum / lessons.length);
  };

  useEffect(() => {
    getSublessonsProgress(subLessons);
  }, [subLessons]);

  useEffect(() => {
    if (scrollElement) {
      const anyLessonInrogress = subLessons.find(
        (lesson) => isLessonStarted(lesson) && lesson.progress !== 1
      );
      if (anyLessonInrogress) {
        handleExpand(true);
        scrollElement.current.scrollIntoView();
      }
    }
  }, [scrollElement]);

  useEffect(() => {
    let progressSum = 0;
    subLessons.forEach((lesson) => {
      progressSum += lesson.progress;
    });
    setCurrentProgress(Math.round((progressSum * 100) / subLessons.length));
  }, [subLessons]);

  useEffect(() => {
    if(isVisible) setLessonsLoaded(true)
  }, [isVisible])

  return (
    <ol className="lessons-page__lessons-list" ref={scrollElement}>
     {isVisible && <li className="lessons-page__lesson-list-element" >
        <header
          className="lessons-page__lesson"
          {...getToggleProps({
            onClick: () => handleExpand((prevExpanded) => !prevExpanded),
          })}
        >
          <div
            className="lessons-page__lesson-image-wrapper"
            style={{
              backgroundImage: `url(${subLessons[0]?.image?.base64})`,
            }}
          ></div>
          <div
            className={
              "lessons-page__lesson-desc" +
              (isExpanded ? " lessons-page__lessons-desc--expanded" : "")
            }
            style={{
              borderColor:
                progress === 0
                  ? "#fff"
                  : progress === 1
                  ? "var(--green-color)"
                  : getLevelColor(subLessons[0].level),
            }}
          >
            <h2 className="lessons-page__lesson-name">
              {subLessons[0].number}.{" "}
              {Store.getLessonName(level, subLessons[0].number, pair.version, pair.languageTo.languageShort)}
            </h2>
            <div className="lessons-page__progress-bar-wrapper">
              <p className="lessons-page__progress-percent">
                {currentProgress}%
              </p>
              <div className="lessons-page__progress-bar">
                <ProgressBar
                  completed={currentProgress}
                  height="12px"
                  bgColor="#95C34B"
                  customLabel=" "
                />
              </div>
            </div>
          </div>
        </header>
        <ol className="lessons-page__sublessons-list" {...getCollapseProps()}>
          {wasExpanded.current && subLessons.map((subLesson) => (
            <li
              className="lessons-page__sublesson-list-element"
              style={{
                transform: `scale(${
                  isLessonStarted(subLesson) && subLesson.progress !== 1
                    ? 1.05
                    : 1
                })`,
              }}
            >
              <header className="lessons-page__sublesson">
                <div
                  className="lessons-page__sublesson-image-wrapper"
                  style={{
                    backgroundColor: !isLessonStarted(subLesson)
                      ? "#ccc"
                      : subLesson.progress === 1
                      ? "var(--green-color)"
                      : getLevelColor(subLesson.level),
                  }}
                >
                  <img style={{ width: "45px" }} src={bubble} />
                </div>
                <div className="lessons-page__sublesson-desc-wrapper">
                  <div className="lessons-page__sublesson-desc">
                    <h2 className="lessons-page__sublesson-name">
                      {Store.getLessonName(level, subLessons[0].number, pair.version, pair.languageTo.languageShort)}{" "}
                      {subLesson.number}.{subLesson.subNumber}
                    </h2>
                    <div className="lessons-page__sublesson-time">
                      {subLesson.wordPairs.length * 2}{" "}
                      {Store.getText("minutes")}
                    </div>
                  </div>
                  <div className="lessons-page__sublesson-buttons-wrapper">
                    <Link
                      className="lessons-page__sublesson-button"
                      disabled={!isLessonStarted(subLesson)}
                      onClick={() => setLessonId(subLesson.id)}
                      style={{
                        backgroundColor: !isLessonStarted(subLesson)
                          ? "#ccc"
                          : subLesson.progress === 1
                          ? "var(--green-color)"
                          : getLevelColor(subLesson.level),
                      }}
                      to={
                        subLesson.progress === 1
                          ? `/platform/activity/${pair.id}/${level}/${subLesson.id}`
                          : `/platform/activity/${pair.id}/${level}`
                      }
                    >
                      {!isLessonStarted(subLesson)
                        ? Store.getText("lessonNotActive")
                        : subLesson.progress === 1
                        ? Store.getText("lessonCompleted")
                        : Store.getText("lessonInProgress")}
                    </Link>
                  </div>
                </div>
              </header>
            </li>
          ))}
        </ol>
      </li>}
    </ol>
  );
}

function LessonsPage(props) {
  const [lessons, setLessons] = useState([]);
  const [languageShort, setLanguageShort] = useState([]);
  const [langPair, setLangPair] = useState();
  const [languagePair, setLanguagePair] = useState();
  const [isGrammarModalOpen, setIsGrammarModalOpen] = useState(false);
  const [isAlphabetModalOpen, setIsAlphabetModalOpen] = useState(false);
  const [isLevelEnded, setIsLevelEnded] = useState(false);
  const [lessonsLoaded, setLessonsLoaded] = useState(false)

  const params = useParams();

  const loadData = async () => {
    const resLessons = await api.get(
      `/lesson/language-pair/${params.pairId}/level/${params.level}`
    );
    setLanguageShort(resLessons.data[0].languagePair.languageTo.languageShort);
    setLanguagePair(resLessons.data[0].languagePair);
    setLangPair(
      `${resLessons.data[0].languagePair.languageFrom.languageShort}-${resLessons.data[0].languagePair.languageTo.languageShort}`
    );
    const lessonNumbers = resLessons.data.map((lesson) => lesson.number);
    const uniqueLessonsNumbers = [...new Set(lessonNumbers)].sort(
      (a, b) => a - b
    );
    const lessonsGroupedByNumber = [];
    uniqueLessonsNumbers.forEach((lessonNumber) => {
      const subLessonsForLesson = resLessons.data.filter(
        (lesson) => lesson.number === lessonNumber
      );
      lessonsGroupedByNumber.push(
        subLessonsForLesson.sort((a, b) => a.subNumber - b.subNumber)
      );
    });

    setIsLevelEnded(resLessons.data.filter(lesson => lesson.progress !== 1).length === 0)

    setLessons(lessonsGroupedByNumber);
  };

  useEffect(() => {
    loadData();
  }, [params]);
  return (
    <div
      className={`lessons-page`}
      style={{
        backgroundImage: `linear-gradient(
          rgba(0, 87, 117, 0.7), 
          rgba(0, 87, 117, 0.7)
        ),
        url("${getLessonBg(languageShort, params.level)}")`,
      }}
    >
      {langPair &&
        ["cs-en", "sk-en", "sk-es", "sk-de"].indexOf(langPair.replace(/[0-9]/g, '')) !== -1 && (
          <GrammarModal
            isModalOpen={isGrammarModalOpen}
            setIsModalOpen={setIsGrammarModalOpen}
            lang={langPair}
          ></GrammarModal>
        )}
      {langPair &&
        ["cs-en", "sk-en", "sk-es", "sk-de",  "cs-ru", "sk-ru" ].indexOf(langPair.replace(/[0-9]/g, '')) !== -1 && (
          <AlphabetModal
            isModalOpen={isAlphabetModalOpen}
            setIsModalOpen={setIsAlphabetModalOpen}
            langFrom={langPair.split("-")[0]}
            langTo={langPair.split("-")[1]}
          ></AlphabetModal>
        )}
      <Link to="/platform">
        <ReturnButton className="lessons-page__return-button" />
      </Link>
      <div>
        {window.innerWidth > 700 ? (
          <LessonNavbarDesktop
            setIsAlphabetModalOpen={setIsAlphabetModalOpen}
            setIsGrammarModalOpen={setIsGrammarModalOpen}
            langPair={langPair}
          />
        ) : (
          <LessonNavbarMobile
            setIsAlphabetModalOpen={setIsAlphabetModalOpen}
            setIsGrammarModalOpen={setIsGrammarModalOpen}
            languageShort={languageShort}
            langPair={langPair}
          />
        )}
        <div className="lessons-page__lists-wrapper">
          {languagePair && lessons.map((subLessons, i) => (
            <ExpandableListElement
              key={subLessons[0].id}
              subLessons={subLessons}
              subLessonsBefore={lessons[i - 1]}
              pair={languagePair}
              level={params.level}
              setLessonsLoaded={setLessonsLoaded}
            ></ExpandableListElement>
          ))}
          {lessonsLoaded && <div className={`lessons-page__test-wrapper ${isLevelEnded ? '' : 'lessons-page__test-wrapper--disabled'}`}>
            <p className="lessons-page__test-text">
              {Store.getText("testLessonScreenText")} {params.level}
            </p>
            <Link to={`/platform/test/${params.pairId}/${params.level}`}>
              <SecondaryButton className="lessons-page__test-button">
              {Store.getText("testLessonScreenButtonText")} 
              </SecondaryButton>
            </Link>
          </div>}
        </div>
      </div>

      <Sidebar
        isSidebarExpandable={window.innerWidth < 768}
        setError={props.setError}
        darkBg
      ></Sidebar>
    </div>
  );
}

export default withParamColector(LessonsPage);
