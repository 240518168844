import Store from "../../../Store";
import getLanguageMap from "./getLanguageMap";

const languageFunFacts = {
  cs: {
    en: [
      "Londýnské metro, vůbec nejstarší metro na světě, je světem sám pro sebe. Londýňané mu říkají „tube“ – „roura“. Běžný anglický výraz pro metro zní „underground“, tedy podzemka.",
      "Angličané vypijí nejvíce čaje na světě, skoro třikrát více než Japonci a 22x více než např. Francouzi.",
      "Hlavní město Anglie, Londýn se dříve jmenovalo Londonium, Ludenwic, Ludenburg",
      "V Anglii žije více než 30.000 lidí se jménem John Smith.",
    ],
    es: [
      "Španělština je po čínštině druhým nejrozšířenějším jazykem na světě. Celkově jím mluví po celém světě více než 406 milionů lidí.",
      "Lízátka Chupa Chups jsou světově proslulá a vynalezena byla Španělem Enricem Bernatem v roce 1958. Původně se měla jmenovat „Gol“, tento název se ale neuchytil. Následně dostala lízátka název „Chups“.",
      "Nejstarší restaurace na světě se nachází v Madridu a je otevřena od roku 1725. Její název je Sobrino de Botin a najíte se tam levněji než v Praze.",
      "Nejstarší existující maják na světě je ve Španělsku a nazývá se Herkulova věž. Byl postaven v prvním století a je stále funkční. Najdete ho v oblasti Galicie, v blízkosti města Santiago de Compostela.",
    ],
    de: [
      "Hlavní město Berlín je 9krát větší než Paříž a má mnohem víc mostů než Benátky. Pyšní se také největší vlakovou stanicí v Evropě.",
      "O tom, že Němci jsou „hlavičky“, není pochyb. Vymysleli automatickou kalkulačku, automobil, benzín, inzulín, kapesní hodinky, televizi, parafín, benzínové a dieselové motory a to, co máme nejradši – gumové medvídky!",
      "Německé úřady mají při výběru jména pro vaše dítě poslední slovo. Zvláštní jména, která neindikují pohlaví (např. Matti), anebo příjmení místo prvního jména, schválena nebudou.",
      "Německo je první zemí, která ustanovila tzv. Daylight saving time, tedy letní čas. Přešla na něj už v roce 1916 kvůli úspoře elektrické energie během 1. světové války.",
    ],
    fr: [
      "Nejnavštěvovanější atrakce v Paříži není Eiffelova věž, ani Louvre, ale Disneyland.",
      "Podle francouzského práva můžete vstoupit do manželství s mrtvým člověkem. Je třeba splnit určitá pravidla, jako třeba, že dotyčný to měl za svého života v plánu.",
      "Ve Francii naleznete nejvyšší horu Evropy - Mont Blanc (4810m).",
      "Bikiny pocházejí taktéž z Francie, původní název zněl Atom, poté se přejmenovaly podle ostrova jménem Bikini, který dříve sloužil k testování atomové bomby.",
    ],
    it: [
      "V Itálii jsou dva nezávislé státy v rámci jedné země: Republika San Marino (40 čtverečních km) a Vatikán (108,7 akrů).",
      "Žádná jiná země v Evropě nemá tolik sopek jako Itálie. Je to způsobeno tím, že italský poloostrov stojí na zlomové linii. Tři hlavní sopky, Etna, Stromboli a Vesuv byly aktivní v posledních 100 letech.",
      "Mnoho italských dětí žije u svých rodičů až do svých 30 let, a to i v případě, že mají práci. Italská rodina je považována za samé srdce italské společnosti.",
      "Ve střední Itálii je kašna, ze které teče červené víno 24 hodin denně. Víno je zdarma pro všechny, kromě opilců a hulvátů.",
    ],
    nl: [
      "Holanďané jsou nejvyšší na světě. Možná je to kvůli časté konzumaci mléčných výrobků.",
      "Hollandse nieuwe, aneb syrový sleď s cibulí je jedním z holandských jídel. Sice nevypadá moc lákavě, ale třeba vás překvapí.",
      "Holandsko je název středozápadní části Nizozemí a někdy se nesprávně používá jako název celé země.",
      "V Nizozemí mají největší spotřebu kávy na obyvatele na světě, v průměru 2,4 šálků kávy na osobu denně.",
    ],
    ru: [
      "Rusko je větší než Pluto. Pluto má 16,6 milionů km čtverečních a Rusko 17.",
      "V Rusku je nejméně 15 tajných měst. Nejsou tam cedule, dopravní značky a do jejich blízkosti nepustí žádného cizince. Skoro nikdo nezná jejich názvy ani přesnou lokalitu.",
      "25 % Rusů umírá před dosažením věku 55 let, ve srovnání s pouhým 1 % v USA. Na vině je vodka.",
      "Bohatí Rusové si místo taxíku berou sanitky, aby se vyhnuli moskevskému provozu.",
    ],
    pl: [
      "Více než třetina Poláků nežije v Polsku. Přestože má Polsko okolo 38 milionů obyvatel, k polské národnosti se hlásí až 60 milionů lidí na celém světě.",
      "Pokud mohou být Poláci na někoho hrdí, tak potom určitě na osobnosti, které se mohou pyšnit získáním prestižní Nobelovy ceny. Je tak opravdu úctyhodné, že ji za celou dobu své existence získalo dohromady 17 osobností!",
      "V Polsku se nachází více než 7 tisíc jezer.",
      "Většina elektřiny v Polsku je vyrobená v tepelných elektrárnách, kde dochází ke spalování uhlí.",
    ],
  },

  sk: {
    en: [
      "Nalepené známky na pohľadnici hore nohami, na ktorých je vyobrazená britská kráľovná, môže byť považované za trestný čin. ",
      "Angličania vypijú najviac čaju na svete, skoro 3x viac ako Japonci a 22x viac ako napr. Francúzi",
      "Väčšina ľudí si myslí, že šampanské vynašli Francúzi, ale technika, ktorú používal Dom Perignon, existovala už v Anglicku, a tak sa Angličania stali vynálezcami šumivého vína. Na zdravie!",
      "Približne 1 miliarda ľudí na celom svete hovorí po anglicky. Je to najglobálnejší jazyk na svete.",
    ],
    es: [
      "V Španielsku majú zaujímavú silvestrovskú tradíciu, podľa ktorej, každý kto zje počas silvestrovskej polnoci s každým úderom vežových hodín jednu guľôčku hroznového vína, bude mať v nasledujúcom roku šťastie.",
      "Španielsko nebolo súčasťou ani prvej, ani druhej svetovej vojny",
      "Španielčina je druhý najpoužívanejší jazyk na svete.",
      "Španielsko bola prvá krajina Európy, ktorá zakázala fajčenie na pracoviskách a v baroch. Stalo sa tak v roku 2006.",
    ],
    de: [
      "Nemecko patrí k popredným knižným krajinám na svete – ročne vydá približne 94 000 titulov. Nachádza sa tu aj najvýznamnejšie podujatie medzinárodného knižného vydavateľstva, Medzinárodný knižný veľtrh vo Frankfurte.",
      "Nemecké zákony zakazujú mená, ktoré neoznačujú pohlavie alebo používajú rodinné meno ako krstné meno. V roku 2014 boli najpopulárnejšími detskými menami Sophie/Sofie pre dievča a Maximilián pre chlapca.",
      "Nemecké zákony zakazujú mená, ktoré neoznačujú pohlavie alebo používajú rodinné meno ako krstné meno. V roku 2014 boli najpopulárnejšími detskými menami Sophie/Sofie pre dievča a Maximilián pre chlapca.",
      "V krajine Mníchov sa pravidelne každý rok koná festival Oktoberfest, najväčší festival na svete. Festival, ktorý bez problémov pokračuje od roku 1810, sa začína posledný septembrový týždeň a končí sa prvý októbrový týždeň.",
    ],
    fr: [
      "V Afrike žije viac ľudí hovoriacich po francúzsky ako v samotnom Francúzsku.",
      "Francúzski novomanželia po vstupe do manželstva takmer nikdy nežijú so svojimi rodičmi. To je vo francúzskej kultúre nezmysel, pretože mladá rodina by mala urobiť všetko pre to, aby sa presťahovala do vlastného priestoru.",
      "Volanie o pomoc May Day nepochádza z angličtiny, ale z francúzskeho m’aide, v preklade pomôžte mi.",
      "Francúzi sa nepozerajú iba na najnovšie výstrelky, ale milujú nákupy z druhej ruky a kupujú použitý tovar. Ľudia vo Francúzsku majú úplne normálny postoj k používaniu vecí, ktoré sú z druhej ruky a nemajú problém kúpiť si interiérové doplnky, ktorý pred nimi použil niekto iný.",
    ],
    it: [
      "Viac ako tretina slobodných talianskych mužov žije stále so svojimi rodičmi. Týka sa to 30-35 ročných mužov.",
      "Najdlhší tunel na svete prepája Taliansko a Švajčiarsko. Gotthard tunnel meria 57 km a tiahne sa popod Alpy. Jeho výstavba trvala 17 rokov.",
      "Všetky gondoly v talianskych Benátkach musia byť natreté čiernou farbou. Tak im to ukladá zákon.",
      "Priemerný taliansky zamestnanec pracuje 20 hodín týždenne. Teda 960 hodín ročne. Toto číslo je najnižšie spomedzi všetkých krajín Európy.",
    ],
    nl: [
      "S priemernou výškou 184 cm u mužou a 170 cm u žien, sú Holanďania najvyšším národom na svete. . Vedci tvrdia, že je to kôli ich DNA, výžive a zabezpečeniu.",
      "Keďže amsterdamská pôda pozostáva z hrubej vrstvy močiarov a hliny, všetky budovy sú postavené na drevených pilieroch, zasadených do hĺbky až 11 metrov. Taký Kráľovský palác stojí na 13 659 pilieroch.",
      "Po Škandinávii spotrebujú Holanďania najväčšie množstvo kávy. Priemerne vypijú až 140 litrov ročne, čo je 3,2 šálky denne na jedného obyvateľa.",
      "Všetky holandské deti sa učia povinne angličtinu v škole a návštevníci Amsterdamu sú častokrát prekvapení plynulosťou,  ktorou Holanďania hovoria anglicky.",
    ],
    ru: [
      "Rusko a Amerika sú v najužšom bode vzdialené len 4 KM.",
      "Bývalý ruský prezident Gorbačov nahral hudobný album plný ruských romantických balád.",
      "Nielen alkohol je veľmi obľúbeným nápojom Ruského národa, ale aj čaj. Tuhá zima sa nerieši iba tvrdým alkoholom, ale aj poriadne horúcim čajom, čo je úplne",
      "Až tretina Rusov verí, že sa Slnko točí okolo Zeme.",
    ],
    pl: [
      "Poľský jazyk používa sedem pádov, čo dáva veľmi zložitú gramatiku. Má tiež veľmi zložitú výslovnosť a mnoho výnimiek z každého jedného pravidla. Ďalším problémom je pravopis, pretože niekedy existujú dva znaky, ktoré označujú ten istý zvuk.",
      "90 % mladých ľudí v Poľsku má stredoškolské vzdelanie a 50 % má akademickú hodnosť.",
      "Poľské jedlo je absolútne vynikajúce! Gołąbki, golonka, żurek a kotlet schabowy vám vyrazia dych, ale poľské jedlo, ktoré MUSÍTE ochutnať, sa volá pierogi!",
      "Vodka ako tradičný poľský alkohol Takmer každý Poliak raz vyskúšal vodku. Dlhé roky to bol najobľúbenejší alkohol podávaný na všetkých svadbách, narodeninových oslavách a výročiach.",
    ],
  },

  pl: {
    en: [
      "W Anglii żyje ponad 55 milionów ludzi.",
      'Z Anglii do Francji można przejechać podwodnym tunelem. Tunel został oddany do użytku w 1994 roku i nosi nazwę "Eurotunel". Jego długość wynosi 50,45 km',
      "W Londynie jest ponad 300 różnych muzeów – zwiedzanie ich zajmie więc co najmniej miesiąc czasu ale i znacznie więcej.",
      "“How are you?” Nie jest pytaniem samym w sobie, ale wstępem do rozmowy – nikt nie oczekuje innej odpowiedzi niż “dziękuje a Ty”.",
    ],
    es: [
      "Nazwa kraju Hiszpania wywodzi się od słowa Ispania, które oznacza Ziemię Królików.",
      "Hiszpania to największy producent bananów w Europie.",
      "Z Hiszpanii pochodzą kultowe lizaki chupa chups (nazwa pochodzi od hiszpańskiego czasownika chupar, który oznacza ssać). Istnieją już od ponad 56 lat.",
      "W Madrycie średnio 200 na 365 dni w roku jest słonecznych.",
    ],
    de: [
      "Niemcy są najludniejszym członkiem Unii Europejskiej z ponad 83 milionami mieszkańców.",
      "Każdego roku w Niemczech spożywa się 800 milionów currywurst. W Berlinie znajduje się nawet muzeum poświęcone currywurst.",
      "Niemcy byli historycznie wielkimi wynalazcami, np. tutaj zbudowano pierwszy samochód, stworzono aspirynę, wydrukowano prasę, rower, mp3 czy wiertarkę.",
      "Niemcy to jeden z największych producentów samochodów na świecie. Jest to również siedziba jednych z najbardziej prestiżowych marek samochodowych na świecie, takich jak Volkswagen, Mercedes. Audi i BMW.",
    ],
    fr: [
      "Większość z listy 50 najdroższych win świata pochodzi z Francji. Najdroższe z nich kosztuje 16 509 euro.",
      "Rząd francuski podarował USA Statuę Wolności. Została zbudowana we Francji i następnie przewieziona statkiem za Atlantyku. Na potrzeby transportu rozebrano ją na 350 elementów.",
      "Luwr to najczęściej odwiedzane muzeum na świecie. Co roku przyjeżdża do niego prawie 10 mln odwiedzających.",
      "Lista francuskich wynalazków jest długa. Znajdują się na niej m.in. łódź podwodna, spadochron, balon, kalkulator.",
    ],
    it: [
      "Wszystkie włoskie słowa kończą się samogłoską: i, a, e, o, u.",
      "We Włoszech wynaleziono m.in. termometr, telefon, baterie, lody, wodę kolońską, okulary.",
      "Ponad połowa Włochów w wieku 18-34 lat mieszka z rodzicami. Wielu Włochów zostaje w domu rodzinnym nawet do 40 roku życia.",
      "Ser parmezan pochodzi z obszaru wokół Parmy we Włoszech. Włosi stworzyli również wiele innych serów, w tym gorgonzola czy mozzarella.",
    ],
    nl: [
      "Holandia posiada najwyższy poziom znajomości języka angielskiego na świecie. Dziewięć na dziesięć osób w kraju mówi po angielsku.",
      "Jest to pierwszy na świecie kraj legalizujący małżeństwo osób tej samej płci od 2001 roku.",
      "Holendrzy to najwyżsi mężczyźni na świecie o średniej wysokości 183 cm.",
      "Vincent Van Gough był znanym holenderskim malarzem. W Amsterdamie znajduje się muzeum poświęcone słynnemu malarzowi.",
    ],
    ru: [
      "W Rosji jest 9 stref czasowych. Pierwotnie było ich 11. W 2010 rząd wprowadził zmiany i zredukował ich liczbę do obecnej.",
      "Rosyjskie lasy nazywane są płucami Europy. Stanowią około 60% powierzchni kraju i ustępują swymi rozmiarami i zdolnością absorpcji dwutlenku węgla tylko lasom amazońskim.",
      "Najdłuższą linie kolejowa na świecie znajduje się w Rosji. Długość linii kolei Transsyberyjskiej wynosi 9200 kilometrów.",
      "Sankt Petersburg trzykrotnie zmieniał nazwę. Na początku XX wieku zwano go Petrogradem, w okresie ZSRR znany był jako Leningrad a od 1991 roku znany jest jako Sankt Petersburg.",
    ],
    pt: [
      "Jednym z najpopularniejszych miejsc na świecie odnośnie surfowania jest Portugalia. Można tutaj surfować 364 dni w roku.",
      "Dąb korkowy pochodzi z Portugalii. Ze względu na różnorodne zastosowania, kraj ten jest największym producentem wyrobów z korka na świecie.",
      "Portugalia jest najstarszym krajem w Europie. Od 1139 r. ma te same określone granice. Nie dziwi więc fakt, że język portugalski jest oficjalnym językiem 9 krajów, a na całym świecie mówi nim ponad 250 milionów ludzi.",
      "Kraj ten ma najdłuższy most w Europie. Most Vasco da Gama w Lizbonie ma długość 17 km.",
    ],
  },

  en: {
    en: [
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
    ],
    es: [
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
    ],
    de: [
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
    ],
    fr: [
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
    ],
    it: [
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
    ],
    nl: [
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
    ],
    ru: [
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
    ],
    pl: [
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
      "To get ONE beer in Germany, you show your thumb. To show your first finger means that you want 2 beers: one with the thumb, and one with the finger.",
    ],
  },
  ro: {
    en: [
      'În Anglia locuiesc peste 55 de milioane de persoane.',
      'Puteți călători din Anglia în Franța printr-un tunel subacvatic. Tunelul a fost pus în funcțiune în 1994 și se numește "Eurotunel". Lungimea sa este de 50,45 km',
      'În Londra există peste 300 de muzee diferite - așa că va fi nevoie de cel puțin o lună de zile pentru a le vizita, dar și de mult mai mult.',
      '"Ce mai faci?" Nu este o întrebare în sine, ci un început de conversație - nimeni nu se așteaptă la un alt răspuns decât "mulțumesc și dumneavoastră".',
    ],
    es: [
      'Numele țării Spania provine de la cuvântul Ispania, care înseamnă Țara iepurilor.',
      'Spania este cel mai mare producător de banane din Europa.',
      'Spania este țara de origine a iconicelor acadele chupa chups (numele provine de la verbul spaniol chupar, care înseamnă a suge). Acestea există de peste 56 de ani.',
      'La Madrid, în medie 200 din 365 de zile pe an sunt însorite.',
    ],
    de: [
      'Germania este cel mai populat stat membru al Uniunii Europene, cu peste 83 de milioane de locuitori.',
      'În fiecare an, în Germania se consumă 800 de milioane de currywursts. Există chiar și un muzeu dedicat currywurst-ului în Berlin.',
      'Din punct de vedere istoric, germanii au fost mari inventatori, de exemplu, prima mașină a fost construită aici, aspirina a fost creată aici, presa, bicicleta, mp3-ul sau burghiul au fost tipărite aici.',
      'Germania este unul dintre cei mai mari producători de automobile din lume. De asemenea, aici se află unele dintre cele mai prestigioase mărci auto din lume, precum Volkswagen, Mercedes. Audi și BMW.',
    ],
    fr: [
      'Majoritatea listei celor mai scumpe 50 de vinuri din lume provin din Franța. Cel mai scump costă 16 509 euro.',
      'Guvernul francez a donat Statuia Libertății Statelor Unite. A fost construită în Franța și apoi transportată cu vaporul peste Atlantic. Acesta a fost dezasamblat în 350 de bucăți pentru transport.',
      'Luvrul este cel mai vizitat muzeu din lume. Acesta primește aproape 10 milioane de vizitatori în fiecare an.',
      'Lista invențiilor franceze este lungă. Acesta include un submarin, o parașută, un balon și un calculator, printre altele.',
    ],
    it: [
      'Toate cuvintele italiene se termină cu o vocală: i, a, e, o, u.',
      'Italia a inventat termometrul, telefonul, bateriile, înghețata, apa de colonie, ochelarii, printre altele.',
      'Mai mult de jumătate dintre italienii cu vârste cuprinse între 18 și 34 de ani locuiesc cu părinții lor. Mulți italieni rămân în casa familiei chiar și până la vârsta de 40 de ani.',
      'Brânza parmezan este originară din zona din jurul orașului Parma, Italia. Italienii au creat și multe alte brânzeturi, printre care gorgonzola sau mozzarella.',
    ],
    nl: [
      'Țările de Jos au cel mai ridicat nivel de cunoaștere a limbii engleze din lume. Nouă din zece locuitori ai țării vorbesc engleza.',
      'Este prima țară din lume care legalizează căsătoria între persoane de același sex din 2001.',
      'Bărbații olandezi sunt cei mai înalți din lume, cu o înălțime medie de 183 cm.',
      'Vincent Van Gough a fost un celebru pictor olandez. În Amsterdam există un muzeu dedicat celebrului pictor.',
    ],
    ru: [
      'În Rusia există 9 fusuri orare. Inițial au fost 11. În 2010, guvernul a făcut modificări și a redus numărul la cel actual.',
      'Pădurile din Rusia sunt numite plămânii Europei. Acestea reprezintă aproximativ 60% din suprafața țării și sunt pe locul al doilea după pădurea amazoniană în ceea ce privește mărimea și capacitatea de absorbție a carbonului.',
      'Cea mai lungă linie de cale ferată din lume se află în Rusia. Lungimea căii ferate transsiberiene este de 9200 de kilometri.',
      'Sankt Petersburg și-a schimbat numele de trei ori. La începutul secolului al XX-lea se numea Petrograd, în perioada URSS a fost cunoscut sub numele de Leningrad, iar din 1991 este cunoscut sub numele de Sankt Petersburg.',
    ],
    pt: [
      'Una dintre cele mai populare destinații de surfing din lume este Portugalia. Aici se poate face surf 364 de zile pe an.',
      'Stejarul de plută este originar din Portugalia. Țara este cel mai mare producător de produse din plută din lume datorită diverselor sale aplicații.',
      'Portugalia este cea mai veche țară din Europa. Acesta are aceleași granițe definite încă din 1139. Nu este de mirare că portugheza este limba oficială a nouă țări și este vorbită de peste 250 de milioane de persoane din întreaga lume.',
      'Țara are cel mai lung pod din Europa. Podul Vasco da Gama din Lisabona are o lungime de 17 km.',
    ],
  },
};

function getLanguageFunFact() {
  const dayOfMonth = new Date().getDate();
  const langFunFacts = languageFunFacts[Store.getUserPlatformLangShort()]
  const langShortFunfact = Object.keys(langFunFacts)[dayOfMonth % (Object.keys(langFunFacts).length)]
  const funFacts = langFunFacts[langShortFunfact]
  return {
    funfact: funFacts[dayOfMonth % 4],
    map: getLanguageMap(langShortFunfact)
  }
}

export default getLanguageFunFact;
