import React, { createContext, Dispatch, useState } from "react";
import { useLocation } from "react-router-dom";
import Store from "../../Store";


export const LanguageContext = createContext();


export const LanguageProvider = ({ children }) => {

  const location = useLocation().pathname;

  // let oldLang = location.split("/")[1];
  // if (oldLang !== "cz" && oldLang !== "pl" && oldLang !== "hu" && oldLang !== "sk") {
   
  //   oldLang =  navigator.language.split("-")[0];
  //   if(oldLang !== "cz" && oldLang !== "pl" && oldLang !== "hu" && oldLang !== "sk"){
  //     oldLang = "en";
  //   }
  // }
  const [lang, setLang] = useState('cs');


  return (
    <LanguageContext.Provider
      value={{
        lang,
        setLang,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};