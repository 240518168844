import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Store from "../../../../Store";
import MainModal from "../MainModal/MainModal";
import getTutorialImage from "./getTutorialImage";
import api from '../../../api/api'
import "./TutorialModal.css";

function TutorialModal(props) {
  const [currentTutorialPoint, setCurrentTutorialPoint] = useState(0);

  useEffect(() => {
    if(props.isModalOpen) {
      api.post('/tutorial-stats', { pageNumber: currentTutorialPoint + 1 })
    }
  }, [currentTutorialPoint])

  const tutorialPointsArray = [
    <div className="tutorial-modal__tutorial-point">
      <p className="tutorial-modal__tutorial-point-text">
        1. {Store.getText("tutorialFirstPointText")}
      </p>
      <div className="tutorial-modal__tutorial-img-wrapper">
        <img
          className="tutorial-page__img"
          src={getTutorialImage("continueLearning", Store.getUserPlatformLangShort())}
        />
      </div>
    </div>,
    <div className="tutorial-modal__tutorial-point">
      <p className="tutorial-modal__tutorial-point-text">
        2. {Store.getText("tutorialSecondPointText")}
      </p>
      <div className="tutorial-modal__tutorial-img-wrapper">
        <img
          className="tutorial-page__img"
          src={getTutorialImage("allLessons", Store.getUserPlatformLangShort())}
        />
      </div>
    </div>,
    <div className="tutorial-modal__tutorial-point">
      <p className="tutorial-modal__tutorial-point-text">
        3. {Store.getText("tutorialThirdPointText")}
      </p>
      <div className="tutorial-modal__tutorial-img-wrapper">
        <img
          className="tutorial-page__img"
          src={getTutorialImage("lessonsList", Store.getUserPlatformLangShort())}
        />
      </div>
    </div>,
    <div className="tutorial-modal__tutorial-point">
      <p className="tutorial-modal__tutorial-point-text">
        4. {Store.getText("tutorialFourthPointText")}
      </p>
      <div className="tutorial-modal__tutorial-img-wrapper">
        <img
          className="tutorial-page__img"
          src={getTutorialImage("learningLevel", Store.getUserPlatformLangShort())}
        />
      </div>
    </div>,
    <div className="tutorial-modal__tutorial-point">
      <p className="tutorial-modal__tutorial-point-text">
        5. {Store.getText("tutorialFifthPointText")}
      </p>
      <div className="tutorial-modal__tutorial-img-wrapper">
        <img
          className="tutorial-page__img"
          src={getTutorialImage("yourActivity", Store.getUserPlatformLangShort())}
        />
      </div>
    </div>,
    <div className="tutorial-modal__tutorial-point">
      <p className="tutorial-modal__tutorial-point-text">
        6. {Store.getText("tutorialSixthPointText")}
      </p>
      <div className="tutorial-modal__tutorial-img-wrapper">
        <img
          className="tutorial-page__img"
          src={getTutorialImage("activeCourse", Store.getUserPlatformLangShort())}
        />
      </div>
    </div>,
    <div className="tutorial-modal__tutorial-point">
      <p className="tutorial-modal__tutorial-point-text">
        7. {Store.getText("tutorialSeventhPointText")}
      </p>
      <div className="tutorial-modal__tutorial-img-wrapper">
        <img
          className="tutorial-page__img"
          src={getTutorialImage("otherCourses", Store.getUserPlatformLangShort())}
        />
      </div>
    </div>,
    <div className="tutorial-modal__tutorial-point">
      <p className="tutorial-modal__tutorial-point-text">
        8. {Store.getText("tutorialEighthPointText")}
      </p>
      <div className="tutorial-modal__tutorial-img-wrapper">
        <img
          className="tutorial-page__img"
          src={getTutorialImage("funFacts", Store.getUserPlatformLangShort())}
        />
      </div>
    </div>,
    <div className="tutorial-modal__tutorial-point">
      <p className="tutorial-modal__tutorial-point-text">
        9. {Store.getText("tutorialNinthPointText")}
      </p>
      <div className="tutorial-modal__tutorial-img-wrapper">
        <img
          className="tutorial-page__img"
          src={getTutorialImage("sidebarMenu", Store.getUserPlatformLangShort())}
        />
      </div>
    </div>,
    <div className="tutorial-modal__tutorial-point">
      <p className="tutorial-modal__tutorial-point-text">
        10. {Store.getText("tutorialTenthPointText")}
      </p>
      <div className="tutorial-modal__tutorial-img-wrapper">
        <img
          className="tutorial-page__img"
          src={getTutorialImage("test", Store.getUserPlatformLangShort())}
        />
      </div>
    </div>,
    <div className="tutorial-modal__tutorial-point">
      <p className="tutorial-modal__tutorial-point-title">
        {Store.getText("tutorialPointTitleLesson")}
      </p>
      <div className="tutorial-modal__tutorial-img-wrapper">
        <img
          className="tutorial-page__img"
          src={getTutorialImage("lessonTutorial", Store.getUserPlatformLangShort())}
        />
      </div>
      <ol className="tutorial-modal__ordered-list">
        <li className="tutorial-modal__ordered-list-element">
          {Store.getText("tutorialLessonFirstPointText")}
        </li>
        <li className="tutorial-modal__ordered-list-element">
          {Store.getText("tutorialLessonSecondPointText")}
        </li>
        <li className="tutorial-modal__ordered-list-element">
          {Store.getText("tutorialLessonThirdPointText")}
          <ul className="tutorial-modal__nested-list">
            <li className="tutorial-modal__nested-list-element">
              {Store.getText("tutorialLessonNestedListFirstPointText")}
            </li>
            <li className="tutorial-modal__nested-list-element">
              {Store.getText("tutorialLessonNestedListSecondPointText")}
            </li>
            <li className="tutorial-modal__nested-list-element">
              {Store.getText("tutorialLessonNestedListThirdPointText")}
            </li>
          </ul>
        </li>
      </ol>
    </div>,
  ];

  return (
    <MainModal
      isModalOpen={props.isModalOpen}
      setIsModalOpen={props.setIsModalOpen}
      className="tutorial-modal"
    >
      <div className="tutorial-modal__content">
        <h2 className="tutorial-modal__title">{Store.getText("tutorial")}</h2>
        <div className="tutorial-modal__content-wrapper">
          <button
            className="tutorial-modal__prev-point-btn"
            disabled={currentTutorialPoint === 0}
            onClick={() => setCurrentTutorialPoint(currentTutorialPoint - 1)}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>

          {tutorialPointsArray[currentTutorialPoint]}
          <button
            className="tutorial-modal__next-point-btn"
            disabled={currentTutorialPoint === tutorialPointsArray.length - 1}
            onClick={() => setCurrentTutorialPoint(currentTutorialPoint + 1)}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
        <div className="tutorial-modal__squares-wrapper">
          {tutorialPointsArray.map((point, i) => (
            <div
              onClick={() => setCurrentTutorialPoint(i)}
              className={
                "tutorial-modal__square" +
                (currentTutorialPoint > i
                  ? " tutorial-modal__square--completed"
                  : "") +
                (currentTutorialPoint === i
                  ? " tutorial-modal__square--active"
                  : "")
              }
            ></div>
          ))}
        </div>
      </div>
    </MainModal>
  );
}

export default TutorialModal;
