import continueLearningCs from "./csImages/continue_learning.jpg";
import allLessonsCs from "./csImages/all_lessons.jpg";
import lessonsListCs from "./csImages/lessons_list.jpg";
import learningLevelCs from "./csImages/learning_level.jpg";
import yourActivityCs from "./csImages/your_activity.jpg";
import activeCourseCs from "./csImages/active_course.jpg";
import otherCoursesCs from "./csImages/other_courses.jpg";
import funFactsCs from "./csImages/fun_facts.jpg";
import sidebarMenuCs from "./csImages/sidebar_menu.jpg";
import lessonTutorialCs from "./csImages/lesson_tutorial.jpg";
import testCs from "./csImages/test.jpg";

import continueLearningPl from "./plImages/continue_learning.jpg";
import allLessonsPl from "./plImages/all_lessons.jpg";
import lessonsListPl from "./plImages/lessons_list.jpg";
import learningLevelPl from "./plImages/learning_level.jpg";
import yourActivityPl from "./plImages/your_activity.jpg";
import activeCoursePl from "./plImages/active_course.jpg";
import otherCoursesPl from "./plImages/other_courses.jpg";
import funFactsPl from "./plImages/fun_facts.jpg";
import sidebarMenuPl from "./plImages/sidebar_menu.jpg";
import lessonTutorialPl from "./plImages/lesson_tutorial.jpg";
import testPl from "./plImages/test.jpg";

import continueLearningSk from "./skImages/continue_learning.jpg";
import allLessonsSk from "./skImages/all_lessons.jpg";
import lessonsListSk from "./skImages/lessons_list.jpg";
import learningLevelSk from "./skImages/learning_level.jpg";
import yourActivitySk from "./skImages/your_activity.jpg";
import activeCourseSk from "./skImages/active_course.jpg";
import otherCoursesSk from "./skImages/other_courses.jpg";
import funFactsSk from "./skImages/fun_facts.jpg";
import sidebarMenuSk from "./skImages/sidebar_menu.jpg";
import lessonTutorialSk from "./skImages/lesson_tutorial.jpg";
import testSk from "./skImages/test.jpg";

import continueLearningRo from "./roImages/continue_learning.jpg";
import allLessonsRo from "./roImages/all_lessons.jpg";
import lessonsListRo from "./roImages/lessons_list.jpg";
import learningLevelRo from "./roImages/learning_level.jpg";
import yourActivityRo from "./roImages/your_activity.jpg";
import activeCourseRo from "./roImages/active_course.jpg";
import otherCoursesRo from "./roImages/other_courses.jpg";
import funFactsRo from "./roImages/fun_facts.jpg";
import sidebarMenuRo from "./roImages/sidebar_menu.jpg";
import lessonTutorialRo from "./roImages/lesson_tutorial.jpg";
import testRo from "./roImages/test.jpg";

const langImg = {
  continueLearning: {
    cs: continueLearningCs,
    pl: continueLearningPl,
    sk: continueLearningSk,
    ro: continueLearningRo,
  },
  allLessons: {
    cs: allLessonsCs,
    pl: allLessonsPl,
    sk: allLessonsSk,
    ro: allLessonsRo,
  },
  lessonsList: {
    cs: lessonsListCs,
    pl: lessonsListPl,
    sk: lessonsListSk,
    ro: lessonsListRo,
  },
  learningLevel: {
    cs: learningLevelCs,
    pl: learningLevelPl,
    sk: learningLevelSk,
    ro: learningLevelRo,
  },
  yourActivity: {
    cs: yourActivityCs,
    pl: yourActivityPl,
    sk: yourActivitySk,
    ro: yourActivityRo,
  },
  activeCourse: {
    cs: activeCourseCs,
    pl: activeCoursePl,
    sk: activeCourseSk,
    ro: activeCourseRo,
  },
  otherCourses: {
    cs: otherCoursesCs,
    pl: otherCoursesPl,
    sk: otherCoursesSk,
    ro: otherCoursesRo,
  },
  funFacts: {
    cs: funFactsCs,
    pl: funFactsPl,
    sk: funFactsSk,
    ro: funFactsRo,
  },
  sidebarMenu: {
    cs: sidebarMenuCs,
    pl: sidebarMenuPl,
    sk: sidebarMenuSk,
    ro: sidebarMenuRo,
  },
  lessonTutorial: {
    cs: lessonTutorialCs,
    pl: lessonTutorialPl,
    sk: lessonTutorialSk,
    ro: lessonTutorialRo,
  },
  test: {
    cs: testCs,
    pl: testPl,
    sk: testSk,
    ro: testRo,
  },
};

const getTutorialImage = (imgName, langShort) => {
  return langImg[imgName][langShort];
};

export default getTutorialImage;
