import './Setting.css';

function Setting({ setSetting, index, setting }) {
    const { languageName, fromEmail, fromName, subject, templateId, active } = setting;

    const setSettingProp = (name, value) => {
        setting[name] = value;
        setSetting(index, setting);
    };

    return (
        <div className="mail-setting__wrapper">
            <span className="mail-setting__language">{languageName}</span>
            <div className="mail-setting__input-wrapper">
                <label className="mail-setting__label">Email nadawcy</label>
                <input
                    className="mail-setting__input"
                    value={fromEmail}
                    onChange={(e) => setSettingProp('fromEmail', e.target.value)}
                />
            </div>
            <div className="mail-setting__input-wrapper">
                <label className="mail-setting__label">Nazwa nadawcy</label>
                <input
                    className="mail-setting__input"
                    value={fromName}
                    onChange={(e) => setSettingProp('fromName', e.target.value)}
                />
            </div>
            <div className="mail-setting__input-wrapper">
                <label className="mail-setting__label">Temat</label>
                <input
                    className="mail-setting__input"
                    value={subject}
                    onChange={(e) => setSettingProp('subject', e.target.value)}
                />
            </div>

            <div className="mail-setting__input-wrapper">
                <label className="mail-setting__label">ID templatki</label>
                <input
                    className="mail-setting__input"
                    value={templateId}
                    onChange={(e) => setSettingProp('templateId', e.target.value)}
                />
            </div>

            <div className="mail-setting__input-wrapper">
                <label className="mail-setting__label">Aktywny</label>
                <input
                    className="mail-setting__checkbox"
                    type="checkbox"
                    checked={active}
                    onChange={(e) => setSettingProp('active', e.target.checked)}
                />
            </div>

        </div>
    );
}

export default Setting