import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {
    faArrowLeft,
    faBars,
    faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Alert,
    Button,
    Card,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    TextareaAutosize,
    TextField,
} from '@mui/material';
import { useEffect } from 'react';
import api from '../platform/api/api';
import { useLayoutEffect } from 'react';
import Store from '../Store';

const drawerWidth = 240;

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -10,
        top: -5,
        border: `2px solid ${theme.palette.background.paper}`,
        borderRadius: '50%',
        padding: '4px',
        fontSize: '12px',
        width: '25px',
        height: '25px',
    },
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

const mdTheme = createTheme();

function DashboardContent() {
    const [open, setOpen] = React.useState(true);
    const [newQuestions, setNewQuestions] = React.useState([]);
    const [currentQuestion, setCurrentQuestion] = React.useState(null);
    const [feedback, setFeedback] = React.useState(null);

    const [answer, setAnswer] = React.useState();

    const toggleDrawer = () => {
        setOpen(!open);
    };

    useEffect(() => {
        api.get('/question').then(({ data }) => setNewQuestions(data));
    }, []);

    useLayoutEffect(() => {
        const question = newQuestions[0];
        if (question) {
            const browserLang = navigator.language.split('-')[0];
            api.post('/question/translate', {
                text: question.question,
                languageShort: browserLang,
            }).then(({ data }) =>
                setCurrentQuestion({ ...question, translatedQuestion: data })
            );
        } else {
            setCurrentQuestion(null);
        }
    }, [newQuestions]);

    const handleAnswer = (e) => {
        e.preventDefault();
        let feedback;
        if (!answer)
            setFeedback({
                type: 'error',
                message: 'Fill answer first',
            });
        else {
            api.post(`/question/${currentQuestion.id}`, { answer })
                .then(() =>
                    setFeedback({
                        type: 'success',
                        message: 'Succesfully send',
                    })
                )
                .catch((e) =>
                    setFeedback({
                        type: 'warning',
                        message: 'Something went wrong try again',
                    })
                );
        }
        setCurrentQuestion((prev) => ({ ...prev, feedback }));
    };

    const handleNext = () => {
        setFeedback();
        setAnswer('');
        setNewQuestions((prev) => {
            const current = [...prev];
            current.shift();
            return current;
        });
    };

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="absolute" open={open}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <FontAwesomeIcon icon={faBars} size="lg" />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            New questions
                        </Typography>
                        <IconButton color="inherit">
                            {/* <NotificationsIcon /> */}
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <FontAwesomeIcon icon={faArrowLeft} size="lg" />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav">
                        <ListItemButton>
                            <ListItemIcon>
                                <StyledBadge
                                    badgeContent={newQuestions.length}
                                    color="primary"
                                >
                                    <FontAwesomeIcon
                                        icon={faEnvelope}
                                        size="2x"
                                    />
                                </StyledBadge>
                            </ListItemIcon>
                            <ListItemText primary="NEW QUESTIONS" />
                        </ListItemButton>
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container
                        maxWidth="lg"
                        sx={{ mt: 4, mb: 4 }}
                        style={{ height: '100%' }}
                    >
                        <Grid
                            container
                            spacing={3}
                            alignItems="center"
                            justifyContent="center"
                            style={{ height: '100%' }}
                        >
                            <Paper
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                                style={{ fontSize: '17px' }}
                            >
                                {currentQuestion ? (
                                    <>
                                        <h3>
                                            {currentQuestion.lesson.number}.
                                            {currentQuestion.lesson.subNumber}
                                            &nbsp;
                                            {Store.getLessonName(
                                                currentQuestion.lesson.level,
                                                currentQuestion.lesson.number,
                                                currentQuestion.languagePair
                                                    .version || 1,
                                                currentQuestion.languagePair
                                                    .languageTo.languageShort
                                            )}
                                        </h3>
                                        <h4>
                                            Language pair:{' '}
                                            {
                                                currentQuestion.languagePair
                                                    .languageFrom.language
                                            }
                                            -
                                            {
                                                currentQuestion.languagePair
                                                    .languageTo.language
                                            }
                                        </h4>
                                        <h4>
                                            User:{' '}
                                            {
                                                currentQuestion.user
                                                    .username
                                            }
                                            (
                                            {
                                                currentQuestion.user
                                                .email
                                            })
                                        </h4>
                                        <img
                                            src={currentQuestion.screen.base64}
                                            style={{
                                                maxWidth: '500px',
                                                maxHeight: '500px',
                                                height: 'auto',
                                                width: 'auto',
                                            }}
                                        />
                                        <p>
                                            <b>Question:</b>
                                        </p>
                                        <p>{currentQuestion.question}</p>
                                        <p>
                                            <b>Translated question:</b>
                                        </p>
                                        <p>
                                            {currentQuestion.translatedQuestion}
                                        </p>
                                        <Box
                                            component="form"
                                            onSubmit={handleAnswer}
                                            noValidate
                                            sx={{ mt: 1 }}
                                        >
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                id="answer"
                                                name="answer"
                                                label="Answer"
                                                autoFocus
                                                multiline
                                                value={answer}
                                                onChange={(e) =>
                                                    setAnswer(e.target.value)
                                                }
                                                style={{ width: '500px' }}
                                            />
                                            {feedback && (
                                                <Alert severity={feedback.type}>
                                                    {feedback.message}
                                                </Alert>
                                            )}

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    sx={{ mt: 3, mb: 2 }}
                                                >
                                                    Send
                                                </Button>

                                                <Button
                                                    type="button"
                                                    variant="outlined"
                                                    sx={{ mt: 3, mb: 2 }}
                                                    onClick={handleNext}
                                                >
                                                    Next
                                                </Button>
                                            </Box>
                                        </Box>
                                    </>
                                ) : (
                                    'NO MORE QUESTIONS'
                                )}
                            </Paper>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default DashboardContent;
