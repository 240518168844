import { Route, Routes } from 'react-router-dom';
import DashboardContent from './Dashboard';
import LoginPage from './pages/LoginPage/LoginPage';

export default (props) => {
    return (
        <Routes>
            <Route index element={<LoginPage {...props}/>} />
            <Route path="dashboard" element={<DashboardContent {...props}/>} />
        </Routes>
    );
};
