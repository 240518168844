import { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';

import './QuestionButton.css';
import questionImg from './question.png';
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import api from '../../../api/api';
import Store from '../../../../Store';
import getQuestionIcon from '../../../common/functions/getQuestionIcon';

function QuestionButton({
    onSend = () => {},
    screenRef,
    lessonId,
    languagePairId,
}) {
    const [question, setQuestion] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const [translated, setTranslated] = useState(false)

    useEffect(() => {
        setTranslated(true)
    })

    const handleSend = (e) => {
        if (!question) return;
        e.preventDefault();
        setIsPopupOpen();
        setTimeout(() => {
            html2canvas(screenRef.current)
                .then((canvas) => canvas.toDataURL('image/png'))
                .then((screen) =>
                    api.post('/question', {
                        lessonId,
                        languagePairId,
                        question,
                        screen,
                    })
                );
            onSend();
        }, 100)
    };

    return (
        <div className={`question-button-wrapper ${translated ? 'question-button-wrapper--translated' : ''}`}>
            {isPopupOpen && (
                <div className="question-popup">
                    <div
                        className="question-popup__surface"
                        onClick={() => setIsPopupOpen()}
                    ></div>
                    <h2 className="question-popup__header">
                        {Store.getText('askExpert')}
                    </h2>
                    <p className="question-popup__text">
                        {Store.getText('askExpertDescription')}
                    </p>
                    <form
                        className="question-popup__form"
                        onSubmit={handleSend}
                    >
                        <input
                            className="question-popup__input"
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                            required
                            autoFocus
                        ></input>
                        <SecondaryButton type="submit" className="question-popup__send-button">
                            {Store.getText('askExpertSend')}
                        </SecondaryButton>
                    </form>
                </div>
            )}
            <button
                type="button"
                className="question-button"
                onClick={() => setIsPopupOpen((prev) => !prev)}
            >
                <img
                    src={getQuestionIcon()}
                    className="question-button__question-img"
                />
            </button>
        </div>
    );
}

export default QuestionButton;
